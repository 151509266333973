import { Route, Routes } from "react-router-dom";
import Leadership from "../components/Leadership";
import Login from "../components/Login";
import ErrorPage from "../components/ErrorPage";
import AverageCalculator from "../components/AverageCalculater";
import MainLayoutContent from "./MainLayoutContent";
import DefaultHomePage from "../components/DefaultLogin";
import LearnChartPattern from "../learning/LearnChartPattern";
import AuthContainer from "../common/AuthContainer";

const Content = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <AuthContainer>
            <ErrorPage />
          </AuthContainer>
        }
      />
      <Route path="/" element={<MainLayoutContent />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/leadership"
        element={
          <AuthContainer>
            <Leadership />
          </AuthContainer>
        }
      />
      <Route path="/average-calculator" element={<AverageCalculator />} />
      <Route
        path="/learn-chart-pattern"
        element={
          <AuthContainer>
            <LearnChartPattern />
          </AuthContainer>
        }
      />
      <Route
        path="/home"
        element={
          <AuthContainer>
            <DefaultHomePage />
          </AuthContainer>
        }
      />
    </Routes>
  );
};

export default Content;
