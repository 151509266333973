import { checkAuth } from "../utils/auth";

const AuthContainer = ({ children }) => {
  const handleAuth = async () => {
    const res = await checkAuth();
    return res.status === 200 && res.data;
  };
  if (localStorage.getItem("token") && handleAuth()) {
    return children;
  } else {
    window.location.replace("/login");
  }
};

export default AuthContainer;
