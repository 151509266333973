/* eslint-disable jsx-a11y/alt-text */
import { Grid } from "@mui/material";
import React from "react";
import Leadership from "../components/Leadership";
import Legalinformation from "../components/Legalinformation";
import MissionAndVission from "../components/MissionAndVission";
import Footer from "./Footer";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import poster1 from "../image/poster1.jpg";
import poster2 from "../image/poster2.jpg";
import poster3 from "../image/poster3.jpg";
import poster4 from "../image/poster4.jpg";
import poster5 from "../image/poster5.jpg";
import HeaderContent from "./HeaderContent";

const MainLayoutContent = () => {
  const images = [
    { url: poster1 },
    { url: poster2 },
    { url: poster3 },
    { url: poster4 },
    { url: poster5 },
  ];

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <Fade
          duration={3000}
          transitionDuration={1000}
          arrows={false}
          pauseOnHover={false}
          cssClass={"height:800px"}
        >
          {images.map((fadeImage, index) => (
            <Grid item container key={index} sx={{ height: "810px" }}>
              <Grid
                item
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={fadeImage.url}
                  alt=""
                />
                <Grid
                  item
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(rgba(9, 30, 62, .7), rgba(9, 30, 62, .7))",
                  }}
                >
                  <HeaderContent />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Fade>
      </Grid>
      <Grid container item md={12} xs={12}>
        <Leadership />
      </Grid>
      <Grid item md={12} xs={12}>
        <MissionAndVission />
      </Grid>
      <Grid
        container
        item
        md={12}
        xs={12}
        sx={{
          borderBottom: "1px solid black",
          marginY: "2rem",
        }}
      >
        <Legalinformation />
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <Footer />
      </Grid>
    </Grid>
  );
};

export default MainLayoutContent;
