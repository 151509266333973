import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Fade } from "react-reveal";

const HeaderContent = () => {
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "1rem",
        paddingTop: "1rem",
      }}
    >
      <Fade left>
        <Box
          sx={{
            border: "1px solid #fff",
            padding: "2rem",
            paddingTop: { md: "2rem", xs: "1rem" },
            marginTop: { md: "0rem", xs: "6rem" },
            height: { xs: "70vh", md: "20vh" },
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "#fff", fontSize: { md: "50px", xs: "45px" } }}
          >
            “If you buy things you do not need,
            <br /> soon you will have to sell things you need.”
          </Typography>
          <Typography variant="h6" sx={{ color: "#fff" }}>
            - Warren Buffett
          </Typography>
        </Box>
      </Fade>
    </Grid>
  );
};

export default HeaderContent;
