import Copyright from "./Copyright";

const Footer = () => {
  return (
    <>
      <Copyright />
    </>
  );
};

export default Footer;
