import { Button, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import BullishEngulfing from "../image/BullishEngulfing.jpg";
import BearishEngulfing from "../image/BearishEngulfing.jpg";

const CommonLearnCard = () => {
  const pattern = [
    {
      title: "Bullish Engulfing",
      img: BullishEngulfing,
      des1: "A Bullish Engulfing Pattern is a technical analysis chart pattern that signals a potential reversal from a downtrend to an uptrend.",
      des2: "It consists of two candlesticks: a small bearish (red or black) candlestick followed by a larger bullish (green or white) candlestick that engulfs or covers the entire body of the previous candlestick.",
    },
    {
      title: "Bearish Engulfing Pattern",
      img: BearishEngulfing,
      des1: "A Bearish Engulfing Pattern is a technical analysis chart pattern used to indicate a potential reversal from an uptrend to a downtrend in the market.",
      des2: "It is a bearish signal, suggesting that sellers are taking control over buyers, and is often used by traders to identify possible short-selling opportunities.",
    },
  ];
  return (
    <>
      {pattern.map((d) => {
        return (
          <Grid
            container
            xs={12}
            md={3}
            sx={{
              borderRadius: "10px",
              boxShadow: 10,
              padding: "1rem",
              mb: 2,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6">{d.title}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ height: "40%", width: 100 }}>
              <CardMedia
                component="img"
                height="150"
                image={d.img}
                alt="BullishEngulfing"
              />
            </Grid>
            <Grid item xs={12} my={2}>
              <Typography
                variant="subtitle2"
                color="#6d6e70"
                sx={{
                  wordWrap: "break-word",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                <li>{d.des1}</li>
              </Typography>
              <Typography
                variant="subtitle2"
                color="#6d6e70"
                sx={{
                  wordWrap: "break-word",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                <li>{d.des2}</li>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{
                  backgroundColor: "#00b9ad",
                  "&:hover": {
                    backgroundColor: "#4a4b4d",
                    color: "#fff",
                  },
                }}
              >
                in details...
              </Button>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default CommonLearnCard;
