import React from "react";
import Portfolio from "./Portfolio";
import { Grid } from "@mui/material";

const DefaultHomePage = () => {
  return (
    <>
      <Grid
        container
        item
        md={12}
        xs={12}
        sx={{ marginTop: "8rem", paddingTop: "0rem" }}
      >
        <Portfolio />
      </Grid>
    </>
  );
};

export default DefaultHomePage;
