import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Fade } from "react-reveal";
import Zoom from "react-reveal/Zoom";

const Portfolio = () => {
  return (
    <>
      <Grid
        item
        md={6}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Fade left>
          <Box
            sx={{
              border: "1px solid #0a255a",
              height: "100px",
              width: "100px",
              borderRadius: "50%",
              background: "#0a255a",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6" sx={{ color: "#fff" }}>
              NCC Ltd
            </Typography>
          </Box>
        </Fade>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Fade right>
          <Box
            sx={{
              border: "1px solid #305ba1",
              height: "150px",
              width: "150px",
              borderRadius: "50%",
              background: "#305ba1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: ".5rem",
            }}
          >
            <Typography variant="h6" sx={{ color: "#fff" }}>
              Trident Ltd
            </Typography>
          </Box>
        </Fade> */}
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom>
          <Box
            sx={{
              border: "1px solid #4a463d",
              height: "250px",
              width: "250px",
              borderRadius: "50%",
              background: "#4a463d",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "1rem",
            }}
          >
            <Fade bottom cascade>
              <Typography variant="h4" sx={{ color: "#fff" }}>
                DiMa Enterprises
              </Typography>
            </Fade>
          </Box>
        </Zoom>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        {/* <Fade bottom>
          <Box
            sx={{
              border: "1px solid #af8e59",
              height: "180px",
              width: "180px",
              borderRadius: "50%",
              background: "#af8e59",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: ".5rem",
            }}
          >
            <Typography variant="h6" sx={{ color: "#fff" }}>
              Delta Corp Ltd
            </Typography>
          </Box>
        </Fade> */}
      </Grid>
    </>
  );
};

export default Portfolio;
