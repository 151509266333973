import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import * as Yup from "yup";
import ContentContainer from "../common/ContentContainer";

const AverageCalculator = () => {
  const history = useNavigate();
  const [sumOfQutAndValue, setSumOfQutAndValue] = useState({
    firstSum: "",
    secondSum: "",
    thirdSum: "",
    qutSum: "",
  });
  const initialValues = {
    currantQut: "",
    newQut: "",
    currantPrice: "",
    newPrice: "",
  };

  const validationSchema = Yup.object({
    currantQut: Yup.string().required("Required"),
    newQut: Yup.string().required("Required"),
    currantPrice: Yup.string().required("Required"),
    newPrice: Yup.string().required("Required"),
  });

  const handleAverage = (values) => {
    const firstSum = values.currantQut * values.currantPrice;
    const secondSum = values.newQut * values.newPrice;
    const thirdSum = firstSum + secondSum;
    const qutSum = values.currantQut + values.newQut;
    setSumOfQutAndValue({
      firstSum,
      secondSum,
      thirdSum,
      qutSum,
    });
  };

  const handleClear = (formik) => {
    setSumOfQutAndValue({
      firstSum: "",
      secondSum: "",
      thirdSum: "",
      qutSum: "",
    });
    formik.setValues(initialValues);
  };

  const styles = {
    textField: {
      width: { md: "70%", xs: "90%" },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          color: "#6d6e70",
          borderColor: "#6d6e70",
        },
        "&:hover fieldset": {
          color: "#6d6e70",
          borderColor: "#6d6e70",
        },
        "&.Mui-focused fieldset": {
          color: "#6d6e70",
          borderColor: "#6d6e70",
        },
      },
      "& .MuiInputLabel-root": {
        color: "#6d6e70",
      },
      "& .MuiInputBase-input": {
        color: "#6d6e70",
        borderColor: "#6d6e70",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#6d6e70 !important",
      },
      "& .MuiInputBase-input::before": {
        color: "#6d6e70",
        borderBottomColor: "#6d6e70",
      },
      "& .MuiInput-underline:after": {
        color: "#6d6e70",
        borderBottomColor: "#6d6e70",
      },
      "& .MuiInput-underline:before": {
        color: "#6d6e70",
        borderBottomColor: "#6d6e70",
      },
      "& .MuiInputBase-input.Mui-focused": {
        color: "#6d6e70",
        borderColor: "#6d6e70",
      },
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
  };
  return (
    <ContentContainer>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleAverage}
        >
          {(formik) => (
            <Form>
              <Grid
                item
                container
                sx={{
                  borderRadius: "4px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  backgroundColor: "#fff",
                  paddingY: "2rem",
                  maxWidth: { md: "50vw", xs: "90vw" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h5">
                    Calculate the Average Stock Price
                  </Typography>
                  <Divider sx={{ width: "90%", paddingTop: ".5rem" }} />
                </Grid>
                <Grid item container xs={12} sx={{ paddingTop: "1rem" }}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="standard"
                      sx={styles.textField}
                      label="Current Quantity"
                      type="number"
                      name="currantQut"
                      onChange={formik.handleChange}
                      value={formik.values.currantQut}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="standard"
                      label="New Quantity"
                      type="number"
                      name="newQut"
                      onChange={formik.handleChange}
                      value={formik.values.newQut}
                      sx={styles.textField}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} sx={{ paddingTop: "1rem" }}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="standard"
                      sx={styles.textField}
                      label="Current Price"
                      type="number"
                      name="currantPrice"
                      onChange={formik.handleChange}
                      value={formik.values.currantPrice}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="standard"
                      label="New Price"
                      type="number"
                      name="newPrice"
                      onChange={formik.handleChange}
                      value={formik.values.newPrice}
                      sx={styles.textField}
                    />
                  </Grid>
                </Grid>
                {sumOfQutAndValue.firstSum && (
                  <Grid item container xs={12} sx={{ paddingTop: "1rem" }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "4px",
                          backgroundColor: "#172b4d",
                          justifyContent: "space-between",
                          paddingX: "1.5rem",
                          paddingY: ".2rem",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#fff",
                            fontSize: { xs: "10px", md: "16px" },
                          }}
                        >
                          Total Investment 1st Buy:
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            color: "#fff",
                          }}
                        >
                          ₹ {sumOfQutAndValue.firstSum}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "4px",
                          backgroundColor: "#172b4d",
                          justifyContent: "space-between",
                          paddingX: "1.5rem",
                          paddingY: ".2rem",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#fff",
                            fontSize: { xs: "10px", md: "16px" },
                          }}
                        >
                          Total Investment 2nd Buy:
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            color: "#fff",
                          }}
                        >
                          ₹ {sumOfQutAndValue.secondSum}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        paddingTop: "1rem",
                      }}
                    >
                      <Typography>
                        Your total quantity is {sumOfQutAndValue.qutSum} at
                        average price of{" "}
                        {sumOfQutAndValue.thirdSum / sumOfQutAndValue.qutSum}{" "}
                        and with total investment of {sumOfQutAndValue.thirdSum}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "1rem",
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{
                      width: "70%",
                      backgroundColor: "#00b9ad",
                      "&:hover": {
                        backgroundColor: "#4a4b4d",
                        color: "#fff",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "1rem",
                  }}
                >
                  <Button
                    variant="outlined"
                    fullWidth
                    type="submit"
                    sx={{
                      width: "70%",
                      color: "#fff",
                      backgroundColor: "#00b9ad",
                      "&:hover": {
                        backgroundColor: "#4a4b4d",
                        color: "#fff",
                      },
                    }}
                    onClick={() => handleClear(formik)}
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "1rem",
                  }}
                >
                  <Button
                    fullWidth
                    startIcon={<ArrowBackIosIcon />}
                    sx={{
                      color: "#6d6e70",
                    }}
                    onClick={() => history("/home")}
                  >
                    Back to Home
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </ContentContainer>
  );
};

export default AverageCalculator;
