import { Grid, Typography } from "@mui/material";
import React from "react";

const Legalinformation = () => {
  return (
    <>
      <Grid
        item
        md={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Typography variant="h4">LEGAL INFORMATION</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#666677",
            marginX: { md: "2rem", xs: "0rem" },
            marginTop: "1rem",
            paddingX: "1rem",
            textAlign: { md: "start", xs: "center" },
          }}
        >
          This website has been prepared solely for the purpose of providing
          information about DiMa Enterprises. and the services and products it
          offers. This website has been compiled in good faith by DiMa
          Enterprises. However, no representation is made as to the completeness
          or accuracy of the information it contains. In particular, you should
          be aware that this information may be incomplete, may contain errors
          or may have become out of date. The reports filed by DiMa Enterprises
          with the Securities and Exchange Commission and listed on this website
          speak only as of the respective dates on which they are filed or used
          by DiMa Enterprises. The contents of those reports can become
          out-of-date. DiMa Enterprises makes no commitment, and disclaims any
          duty, to update any of those reports. DiMa Enterprises reserves the
          right to add, modify or delete any information at this website at any
          time. This publication and any references to products or services are
          provided "as is" without any warranty or implied term of any kind.
          Reproduction or distribution of any materials obtained on this website
          or linking to this website without written permission is prohibited.
        </Typography>
      </Grid>
    </>
  );
};

export default Legalinformation;
