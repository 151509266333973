import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import useProgress from "../hooks/useProgress";
import axios from "axios";

const LoginForm = () => {
  const navigate = useNavigate();
  const initialValues = { username: "", password: "" };
  const [login] = useProgress((data) => axios.post("/signin", data));

  const validationSchema = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const handleLogin = async (values) => {
    if (values) {
      login(values)
        .then(async (res) => {
          navigate("/home");
          toast.success(res.data.message);
          localStorage.setItem("token", res.data.token);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Login Not Successfully");
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      height="100vh"
      width="100vw"
    >
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {(formik) => (
            <Form>
              <Grid
                item
                container
                sx={{
                  display: "flex",
                  borderRadius: "4px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  width: { md: "50vw", xs: "80vw" },
                }}
              >
                <Grid
                  item
                  container
                  md={6}
                  xs={12}
                  sx={{
                    backgroundColor: "#00b9ad",
                    display: { md: "flex", xs: "none" },
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: "2rem",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography variant="h5" sx={{ color: "#fff" }}>
                      "The most Important quality for an investor is
                      temperament, not intellect."
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ padding: "2rem", paddingTop: "0rem" }}
                  >
                    <Typography sx={{ color: "#fff" }}>
                      - Warren Buffett
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  md={6}
                  xs={12}
                  sx={{
                    paddingY: "3rem",
                    maxWidth: { md: "100vw", xs: "80vw" },
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" sx={{ color: "#6d6e70" }}>
                      Sign In
                    </Typography>
                    <Divider
                      sx={{
                        width: "95%",
                        paddingTop: ".5rem",
                        borderColor: "#6d6e70",
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "1rem",
                    }}
                  >
                    <TextField
                      variant="standard"
                      sx={{
                        width: "70%",
                        "& .MuiInputLabel-root": {
                          color: "#6d6e70",
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#6d6e70 !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#6d6e70",
                          },
                          "&:hover fieldset": {
                            borderColor: "#6d6e70",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#6d6e70",
                          },
                        },
                        "& .MuiInputBase-input": {
                          borderColor: "#6d6e70",
                        },
                        "& .MuiInputBase-input::before": {
                          color: "#6d6e70",
                          borderBottomColor: "#6d6e70",
                        },
                        "& .MuiInput-underline:after": {
                          color: "#6d6e70",
                          borderBottomColor: "#6d6e70",
                        },
                        "& .MuiInput-underline:before": {
                          color: "#6d6e70",
                          borderBottomColor: "#6d6e70",
                        },
                        "& .MuiInputBase-input.Mui-focused": {
                          color: "#6d6e70",
                          borderColor: "#6d6e70",
                        },
                      }}
                      label="User Name"
                      type="text"
                      name="username"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "1rem",
                    }}
                  >
                    <TextField
                      variant="standard"
                      label="Password"
                      type="text"
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      sx={{
                        width: "70%",
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#6d6e70 !important",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#6d6e70",
                          },
                          "&:hover fieldset": {
                            borderColor: "#6d6e70",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#6d6e70",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#6d6e70",
                        },
                        "& .MuiInputBase-input": {
                          borderColor: "#6d6e70",
                        },
                        "& .MuiInputBase-input::before": {
                          color: "#6d6e70",
                          borderBottomColor: "#6d6e70",
                        },
                        "& .MuiInput-underline:after": {
                          color: "#6d6e70",
                          borderBottomColor: "#6d6e70",
                        },
                        "& .MuiInput-underline:before": {
                          color: "#6d6e70",
                          borderBottomColor: "#6d6e70",
                        },
                        "& .MuiInputBase-input.Mui-focused": {
                          color: "#6d6e70",
                          borderColor: "#6d6e70",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        width: "70%",
                        color: "#fff",
                        backgroundColor: "#00b9ad",
                        "&:hover": {
                          backgroundColor: "#6d6e70",
                          color: "#fff",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Box>
  );
};

export default LoginForm;
