import { Grid, Link, Typography } from "@mui/material";
import React from "react";

const Leadership = () => {
  return (
    <>
      <Grid
        item
        md={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1.5rem",
        }}
      >
        <Typography variant="h4">LEADERSHIP</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography
          variant="h6"
          fontWeight="600"
          sx={{ marginLeft: { md: "1.5rem", xs: "1.5rem" } }}
        >
          Dikshant Savaliya
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#666677",
            marginRight: { md: "1.5rem", xs: "1.5rem" },
            marginLeft: { md: "1.5rem", xs: "1.5rem" },
            // textAlign: { md: "start", xs: "center" },
          }}
        >
          You probably know that I don't make stock recommendations. However, I
          have two thoughts regarding your personal expenditures that can save
          you real money. I'm suggesting that you call on the services of two
          subsidiaries of DiMA Enterprises: LIC and Star Life Health.
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            marginRight: { md: "1.5rem", xs: "1.5rem" },
            marginLeft: { md: "1.5rem", xs: "1.5rem" },
            marginTop: "1rem",
            color: "#666677",
            // textAlign: { md: "start", xs: "center" },
          }}
        >
          I estimate that about 40% of all auto drivers in the country can save
          money by insuring with LIC. The figure is not 100% because insurers
          differ in their underwriting judgments, with some favoring drivers who
          live in certain geographical areas and work in certain occupations
          more than LIC does. I believe, however, that LIC more frequently
          offers the low price than does any other national carrier selling
          insurance to all comers. You can quickly find out whether you can save
          money by going to{" "}
          <Link href="https://licindia.in/" sx={{ textDecoration: "none" }}>
            www.licindia.
          </Link>
          in or by calling +91-022 6827 6827.
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            marginRight: { md: "1.5rem", xs: "1.5rem" },
            marginLeft: { md: "1.5rem", xs: "1.5rem" },
            marginTop: "1rem",
            color: "#666677",
            // textAlign: { md: "start", xs: "center" },
          }}
        >
          Fine jewelry, watches and giftware will almost certainly cost you less
          at Titen's. I've looked at the figures for all publicly-owned jewelry
          companies and the contrast with Titen's is startling. Our one-store
          operation, with its huge volume, enables us to operate with costs that
          are fully 15-20 percentage points below those incurred by our
          competitors. We pass the benefits of this low-cost structure along to
          our customers.
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            marginRight: { md: "1.5rem", xs: "1.5rem" },
            marginLeft: { md: "1.5rem", xs: "1.5rem" },
            marginTop: "1rem",
            color: "#666677",
            // textAlign: { md: "start", xs: "center" },
          }}
        >
          Every year Titen's sends out thousands of selections to customers who
          want a long-distance opportunity to inspect what it offers and decide
          which, if any, item they'd like to purchase. We do a huge amount of
          business in this low-key way, which allows the shopper to conveniently
          see the exceptional values that we offer. Call Titen's (1800-266-0123)
          and save substantial money on your next purchase of jewelry.
        </Typography>
      </Grid>
    </>
  );
};

export default Leadership;
