import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppLayout from "./layout";
import MainLayout from "./layout/MainLayout";

function App() {
  return (
    <>
      <BrowserRouter>
        <MainLayout>
          <ToastContainer />
          <AppLayout />
        </MainLayout>
      </BrowserRouter>
    </>
  );
}

export default App;
