import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import pnglogo from "../image/pnglogo.png";
import { useState } from "react";
import { Menu, useMediaQuery, useScrollTrigger, useTheme } from "@mui/material";
import { Login } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import CalculateIcon from "@mui/icons-material/Calculate";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 240;

export const BeforeLogin = [
  {
    parent: "Average Calculator",
    route: "/average-calculator",
    pageIcon: <CalculateIcon />,
  },
  {
    parent: "Login",
    route: "/login",
    pageIcon: <Login />,
  },
];

export const AfterLogin = [
  {
    parent: "Average Calculator",
    route: "/average-calculator",
    pageIcon: <CalculateIcon />,
  },
  {
    isComponent: true,
    name: "consoleMenu",
  },
];

const MainLayout = (props) => {
  const history = useNavigate();
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openMobileSubmenu, setOpenMobileSubmenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const authValue = localStorage.getItem("token");

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const handleScrollToTop = () => {
    history("/");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    history("/");
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const logoutButton = () => {
    return isMdDown ? (
      <Button
        sx={{
          display: "flex",
          textAlign: "center",
          color: "#00b9ad",
        }}
        onClick={handleLogout}
        startIcon={<LogoutIcon />}
      >
        <ListItemText primary={"Logout"} />
      </Button>
    ) : (
      <Button
        variant="contained"
        sx={{
          paddingY: ".6rem",
          color: "#fff",
          backgroundColor: "#00b9ad",
          "&:hover": {
            backgroundColor: "#fff",
            color: "#00b9ad",
          },
        }}
        onClick={handleLogout}
        startIcon={<LogoutIcon />}
      >
        Logout
      </Button>
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickForMobileSubMenu = () => {
    setOpenMobileSubmenu(!openMobileSubmenu);
    setMobileOpen(!mobileOpen);
  };

  const consoleMenu = () => {
    if (isMdDown) {
      return (
        <>
          <ListItemButton
            onClick={handleClickForMobileSubMenu}
            sx={{
              display: "flex",
              gap: ".5rem",
              color: "#00b9ad",
            }}
          >
            <DonutLargeIcon />
            <ListItemText primary="Console" />
            {openMobileSubmenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openMobileSubmenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <Link
                  to="/learn-chart-pattern"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                    color: "#00b9ad",
                  }}
                >
                  <TroubleshootIcon />
                  <ListItemText primary="Learn Chart Pattern" />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
        </>
      );
    }

    return (
      <Box>
        <Button
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="contained"
          startIcon={<DonutLargeIcon />}
          sx={{
            paddingY: ".6rem",
            mr: ".4rem",
            color: "#fff",
            backgroundColor: "#6d6e70",
            "&:hover": {
              backgroundColor: "#4a4b4d",
              color: "#fff",
            },
          }}
        >
          Console
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ mt: 1 }}
        >
          <Button
            startIcon={<TroubleshootIcon sx={{ color: "#6d6e70" }} />}
            onClick={handleClose}
            sx={{ paddingX: "1rem" }}
          >
            <Link
              to="/learn-chart-pattern"
              style={{
                textDecoration: "none",
                color: "#6d6e70",
                display: "flex",
                alignItems: "center",
              }}
            >
              Learn Chart Pattern
            </Link>
          </Button>
          <Divider />
        </Menu>
      </Box>
    );
  };

  const componentMapping = {
    consoleMenu,
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        DiMa Enterprises
      </Typography>
      <Divider />
      <List>
        {(authValue ? AfterLogin : BeforeLogin).map((item) => {
          if (item?.isComponent) {
            return componentMapping[item.name]();
          }
          return (
            <ListItem key={item.parent} disablePadding>
              <Link
                to={item.route}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                  flexDirection: "row",
                  marginRight: ".5rem",
                }}
              >
                <ListItemButton
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    gap: ".5rem",
                    color: "#00b9ad",
                  }}
                >
                  {item.pageIcon} <ListItemText primary={item.parent} />
                </ListItemButton>
              </Link>
            </ListItem>
          );
        })}
        {authValue ? (
          <ListItem key={"logout-button"} disablePadding>
            <ListItemButton sx={{ display: "flex", textAlign: "center" }}>
              {logoutButton()}
            </ListItemButton>
          </ListItem>
        ) : null}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          backgroundColor: !trigger ? "transparent" : "#fff",
          color: "#fff",
          padding: ".5rem",
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon sx={{ color: "#00b9ad" }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                alt="logo"
                src={pnglogo}
                height="80px"
                width="130px"
                style={{ cursor: "pointer" }}
                onClick={handleScrollToTop}
              />
              <Typography
                variant="h6"
                sx={{
                  pl: "2rem",
                  display: { md: "flex", xs: "none" },
                  justifyContent: "flex-end",
                  color: !trigger ? "#fff" : "#4a463d",
                }}
              >
                DiMa Enterprises operates as a venture capital firm.
              </Typography>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              {(authValue ? AfterLogin : BeforeLogin).map((item) => {
                if (item?.isComponent) {
                  return componentMapping[item.name]();
                }
                return (
                  <Link
                    to={item.route}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      flexDirection: "row",
                      marginRight: ".5rem",
                    }}
                  >
                    <Button
                      key={item.parent}
                      variant="contained"
                      sx={{
                        color: "#00b9ad",
                        backgroundColor: "#fff",
                        " &:hover": {
                          color: "#fff",
                          background: "#00b9ad",
                        },
                      }}
                      startIcon={item.pageIcon}
                    >
                      {item.parent}
                    </Button>
                  </Link>
                );
              })}
              {authValue ? logoutButton() : null}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {children}
    </>
  );
};

MainLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainLayout;
