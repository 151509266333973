import { Divider, Grid, Typography } from "@mui/material";
import ContentContainer from "../common/ContentContainer";
import React from "react";
import CommonLearnCard from "./CommonLearnCard";

const LearnChartPattern = () => {
  return (
    <ContentContainer>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 1,
          }}
        >
          <Typography variant="h4" color="#00b9ad">
            Learn Chart Pattern
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ width: "100%" }} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: { md: "flex", xs: "block" }, m: 2 }}
          gap={2}
        >
          <CommonLearnCard />
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default LearnChartPattern;
