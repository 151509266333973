import { Grid, Typography } from "@mui/material";
import React from "react";

const MissionAndVission = () => {
  return (
    <Grid container sx={{ padding: { md: "4rem", xs: "0rem" } }}>
      <Grid item md={6} xs={12} sx={{ background: "#00b9ad", color: "#fff" }}>
        <Typography
          variant="h4"
          fontWeight="600"
          sx={{
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          VISION
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#fff",
            padding: "1rem",
            pt: ".5rem",
            textAlign: "center",
          }}
        >
          "To enhance the economic value of our clients' business by providing
          integrated financial & investments products and services"
        </Typography>
      </Grid>
      <Grid item md={6} xs={12} sx={{ background: "#6d6e70", color: "#fff" }}>
        <Typography
          variant="h4"
          fontWeight="600"
          sx={{
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          MISSION
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#fff",
            padding: "1rem",
            pt: ".5rem",
            textAlign: "center",
          }}
        >
          "To be amongst the most admired financial institutions in India with a
          reputation built on strong ethics and trust"
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MissionAndVission;
