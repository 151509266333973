import { Grid } from "@mui/material";
import React from "react";

const ContentContainer = ({ children }) => {
  return (
    <Grid sx={{ pt: "6rem", height: "100vh" }}>
      <Grid>{children}</Grid>
    </Grid>
  );
};

export default ContentContainer;
