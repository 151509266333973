import { Box, Typography } from "@mui/material";

const Copyright = () => {
  return (
    <Box>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: { md: "start", xs: "center" },
        }}
      >
        Copyright © 2022-2023 DiMa Enterprises|All Right Reserved.
      </Typography>
    </Box>
  );
};

export default Copyright;
