import Content from "./Content";

const AppLayout = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AppLayout;
